
// images from public folder
const PDF = `${process.env.PUBLIC_URL}/assets/pdf.webp`;
const Doc = `${process.env.PUBLIC_URL}/assets/doc.webp`;
const Image = `${process.env.PUBLIC_URL}/assets/image.webp`;
const Sheet = `${process.env.PUBLIC_URL}/assets/sheet.webp`;
const Csv = `${process.env.PUBLIC_URL}/assets/csv.webp`;
const Audio = `${process.env.PUBLIC_URL}/assets/mp3.webp`;
const Ppt = `${process.env.PUBLIC_URL}/assets/ppt.webp`;
const Txt = `${process.env.PUBLIC_URL}/assets/txt.webp`;
const Video = `${process.env.PUBLIC_URL}/assets/video.webp`;
const Svg = `${process.env.PUBLIC_URL}/assets/svg.webp`;

export const fileIcons = {
  // Images
  "image/png": Image,
  "image/jpeg": Image,
  "image/svg+xml": Svg, // SVG files

  // Documents
  "application/pdf": PDF,
  "application/msword": Doc,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
    Doc,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": Sheet,
  "application/vnd.ms-excel": Sheet,
  "application/vnd.ms-powerpoint": Ppt,
  "application/vnd.openxmlformats-officedocument.presentationml.presentation":
    Ppt,

  // Text
  "text/plain": Txt,
  "text/csv": Csv,

  // Video formats
  "video/mp4": Video,
  "video/x-msvideo": Video, // AVI
  "video/x-matroska": Video, // MKV
  "video/webm": Video, // WebM
  "video/quicktime": Video, // MOV
  "application/x-mpegurl": Video, // MPEG URLs
  "video/x-ms-wmv": Video, // WMV

  // Audio formats
  "audio/mpeg": Audio, // MP3
  "audio/mp3": Audio, // MP3 alternative MIME type
  "audio/wav": Audio, // WAV format
  "audio/ogg": Audio, // OGG format
};
