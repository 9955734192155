import { create } from "zustand";
import axios from "axios";
import { supabase } from "../helper/supabaseClient";
import secureLocalStorage from "react-secure-storage";
import { auth } from "../helper/firebaseClient";
import { api } from "../utils/axios-instance";

const useAuth = create((set) => ({
  token: null,
  isFileViewerOpen: false,
  screenshotDetected: false,
  users: [],
  profileData: null,
  profileIsPending: true,
  location: null,
  error: {},
  coordinates: [],
  filteredData: [],

  setToken: (newToken) => {
    set({ token: newToken });
    secureLocalStorage.setItem("token", JSON.stringify(newToken));
  },
  openFileViewer: () => set({ isFileViewerOpen: true }),
  closeFileViewer: () => set({ isFileViewerOpen: false }),
  setScreenshotDetected: (value) => set({ screenshotDetected: value }),
  setUsers: (value) => set({ users: value }),
  setProfileData: (value) => set({ profileData: value }),
  setProfileIsPending: (value) => set({ profileIsPending: value }),
  setLocation: (value) => set({ location: value }),
  setError: (value) => set({ error: value }),
  setCoordinates: (value) => set({ coordinates: value }),
  setFilteredData: (value) => set({ filteredData: value }),

  detectPrintScreen: (event) => {
    console.log("Key pressed:", event.key, event.code, event);
    const { key, code, metaKey, shiftKey, ctrlKey, altKey } = event;

    switch (true) {
      case metaKey && shiftKey && key === "3":
      case metaKey && shiftKey && key === "4":
      case metaKey && shiftKey && key === "6":
      case ctrlKey && key === "p":
      case altKey && (key === "PrintScreen" || key === "Insert"):
      case metaKey && (key === "PrintScreen" || key === "Insert"):
      case altKey && metaKey && (key === "PrintScreen" || key === "Insert"):
      case key === "PrintScreen" ||
        key === "Insert" ||
        code === "PrintScreen" ||
        code === 44:
      case metaKey && shiftKey && key === "s":
      case metaKey && shiftKey:
        event.preventDefault();
        console.log(`${event.key} pressed!`);

        useAuth.setState({ screenshotDetected: true });

        // Remove the blur class after 3 seconds
        setTimeout(() => {
          useAuth.getState().checkClipboardForImage();
          useAuth.setState({ screenshotDetected: false });
        }, 3000);

        useAuth.getState().monitorClipboard();
        useAuth.getState().checkClipboardForImage();
        useAuth.getState().screenshotAlert();
        break;

      default:
        break;
    }
  },

  handleContextMenu: (event) => {
    event.preventDefault();
    console.log("Right-click prevented!");
  },

  monitorClipboard: async () => {
    // Delay to allow the clipboard to update
    await new Promise((resolve) => setTimeout(resolve, 100));

    navigator.clipboard
      .read()
      .then((clipText) =>
        console.log("Clipboard content changed:", clipText[0].types)
      )
      .catch((error) => console.error("Error reading clipboard:", error));
  },

  checkClipboardForImage: async () => {
    await new Promise((resolve) => setTimeout(resolve, 200));
    try {
      const data = await navigator.clipboard.read();
      const types = data[0].types;
      let hasImageType = false;
      for (const type of types) {
        if (type.startsWith("image")) {
          hasImageType = true;
          break;
        }
      }
      if (hasImageType) {
        console.log("Screenshot captured.", types);

        // Assuming the first item in the clipboard data is the image
        const imageBlob = await data[0].getType("image/png");
        // await uploadImageToSupabase(imageBlob);
        console.log("Image blob:", imageBlob);
        useAuth.setState({ screenshotDetected: true });

        await useAuth.getState().screenshotAlert();

        setTimeout(() => set({ screenshotDetected: false }), 3000);
      } else {
        useAuth.setState({ screenshotDetected: false });
      }
    } catch (error) {
      console.error("Error reading clipboard:", error);
    }
  },

  screenshotAlert: async (fileId) => {
    try {
      const token = auth.currentUser && (await auth.currentUser.getIdToken());

      if (fileId) {
        const res = await api.post(
          `/file/logEvent/${fileId}?event=screenshot`,
          {
            event: "screenshot",
            idToken: token,
          }
        );
        console.log("screenshot log :", res);
      }
    } catch (error) {
      console.log(error);
    }
  },

  fetchRecentFiles: async () => {
    try {
      const recentFilesFromBackend = await api.get(`/file/files`);
      if (recentFilesFromBackend) {
        const mappedFiles = recentFilesFromBackend.data.map(async (file) => {
          try {
            const { data } = await supabase.storage
              .from("avatar")
              .getPublicUrl(file.owner_email);

            return {
              id: file.id,
              name: file.name.substring(0, 80),
              size: useAuth.getState().formatFileSize(file.metadata.size),
              dept: file.dept_name,
              publicUrl: data.publicUrl,
              owner: file.owner_email,
              mimetype: file.metadata.mimetype,
              status: "Team",
              security: "Enhanced",
              drive_link: file?.drive_link,
              drive_file_id: file?.drive_file_id,
              lastUpdate: new Date(file.metadata.lastModified).toLocaleString(
                "en-IN",
                {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  hour12: true,
                }
              ),
            };
          } catch (error) {
            console.log("Error while getting public URL:", error);
            return null;
          }
        });

        const resolvedFiles = await Promise.all(mappedFiles);
        useAuth.setState({
          filteredData: resolvedFiles.filter((file) => file !== null),
        });
        // console.log("Files:", filteredFiles);
        // localStorage.setItem("filteredFiles", JSON.stringify(filteredFiles));
      }
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  },
  //   fetchRecentFiles();
  // }, []);

  formatFileSize: (sizeInBytes) => {
    const units = ["B", "KB", "MB", "GB"];
    let size = sizeInBytes;
    let unitIndex = 0;
    while (size >= 1024 && unitIndex < units.length - 1) {
      size /= 1024;
      unitIndex++;
    }
    return size.toFixed(2) + " " + units[unitIndex];
  },

  fetchProfileData: async () => {
    set({ profileIsPending: true });
    try {
      const token = await auth.currentUser.getIdToken();

      if (token) {
        const res = await axios.post(
          `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/getProfileInfo/`,
          { idToken: token }
        );

        // Set the profile data in the state
        useAuth.setState({ profileData: res.data.user });
        secureLocalStorage.setItem(
          "profileData",
          JSON.stringify(res.data.user)
        );
      }
    } catch (error) {
      console.log("error occurred while fetching profile data", error);
    } finally {
      useAuth.setState({ profileIsPending: false });
    }
  },
  setSessionToken: (newToken) => {
    set({ newToken });
    secureLocalStorage.setItem("token", JSON.stringify(newToken));
  },

  refreshAccessToken: async () => {
    try {
      console.log("Refreshing token...");
      let token = JSON.parse(secureLocalStorage.getItem("token"));
      if (!token) {
        console.log("No token available");
        return;
      }
      const refresh_token = token.session.refresh_token;
      const { data, error } = await supabase.auth.refreshSession({
        refresh_token,
      });
      if (data && data.session) {
        console.log("Token Refreshed Successfully", data);
        useAuth.setSessionToken({ token: data });
      } else if (error || !data) {
        console.error("Error refreshing token:", error);
      }
    } catch (error) {
      // handle error accordingly
      console.error("Error refreshing token:", error);
    }
  },

  getGeolocation: () => {
    let watchId;
    if ("geolocation" in navigator) {
      watchId = navigator.geolocation.watchPosition(
        (position) => {
          const { coords } = position;
          useAuth.setState({
            location: {
              latitude: coords.latitude,
              longitude: coords.longitude,
            },
          });
        },
        (error) => useAuth.setState({ error: error.message })
      );
    } else {
      useAuth.setState({
        error: "Geolocation is not supported in your browser.",
      });
    }
    return () => {
      if (watchId) navigator.geolocation.clearWatch(watchId);
    };
  },
  listLocations: async () => {
    try {
      const locations = await api.get(`/file/file/listLocation`);
      // console.log("locations :", locations.data.features);
      set({ coordinates: locations.data.features });
    } catch (error) {
      console.log("Error while listing location Coordinates.", error);
    }
  },
}));

export default useAuth;
