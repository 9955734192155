import { Skeleton } from "@mui/material";

export const LoadingCard = () => {
  return (
    <div className="border border-gray-200 p-3 rounded-2xl shadow-sm">
      <span className="">
        <Skeleton variant="rounded" height={90} width="100%" />
      </span>

      <span>
        <span className="flex flex-row justify-between items-center my-1 px-1">
          <span className="w-full text-sm text-gray-500 font-light">
            <Skeleton height={18} width="70%" />
          </span>
          <Skeleton height={18} width="20%" />
        </span>
      </span>
    </div>
  );
};

export const LoadingSection = ({ height = 120 }) => {
  return (
    <div className="p-3 rounded-2xl shadow-sm">
      <Skeleton variant="rounded" height={height} width="100%" />
    </div>
  );
};
