import {
  Spreadsheet,
  getCell,
  getRangeIndexes,
} from "@syncfusion/ej2-react-spreadsheet";

export const MROUND = (numberStr, multipleStr) => {
  const number = parseFloat(numberStr);
  const multiple = parseFloat(multipleStr);

  if (isNaN(number) || isNaN(multiple) || multiple === 0) {
    // Handle invalid inputs or if the multiple is 0
    return 0;
  }
  if (multiple === 0) {
    return 0;
  }

  // Calculate the remainder of number divided by multiple
  const remainder = number % multiple;

  console.log({ remainder: 90, number });

  if (number >= 0) {
    // For positive numbers
    if (remainder >= multiple / 2) {
      // Round up
      return number + (multiple - remainder);
    } else {
      // Round down
      return number - remainder;
    }
  } else {
    // For negative numbers
    if (Math.abs(remainder) >= Math.abs(multiple) / 2) {
      // Round up (more negative)
      return number - (Math.abs(multiple) - Math.abs(remainder));
    } else {
      // Round down (less negative)
      return number - remainder;
    }
  }
};

export const STDEV = (firstValue, ...restValues) => {
  let values = [];
  console.log({ firstValue, restValues });
  // Check if the first argument is a range (e.g., "C12:H12")
  const isRange =
    typeof firstValue === "string" &&
    firstValue.includes(":") &&
    restValues.length === 1;
  console.log("isRange", isRange);
  if (isRange) {
    const spreadsheet = restValues[0];
    const sheet = spreadsheet.getActiveSheet();

    // Get the indexes of the specified range (e.g., C12:H12 -> [11, 2, 11, 7])
    const index = getRangeIndexes(firstValue);
    console.log("rangeIndexes", index);

    // Iterate over the range to extract cell values
    for (let i = index[0]; i <= index[2]; i++) {
      for (let j = index[1]; j <= index[3]; j++) {
        const cell = getCell(i, j, sheet);
        console.log("cell", cell);
        if (cell) {
          const num = parseFloat(cell.value);
          if (!isNaN(num)) {
            values.push(num);
          }
        }
      }
    }
  } else {
    // If specific numbers are provided as arguments, use them directly
    values = [firstValue, ...restValues].map(Number).filter((n) => !isNaN(n));
  }

  console.log("values", values);
  const n = values.length;

  if (n <= 1) {
    // Standard deviation calculation requires at least 2 data points
    return 0;
  }

  // Calculate the mean (average) of the array
  const mean = values.reduce((a, b) => a + b, 0) / n;

  // Calculate the variance
  const variance =
    values.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / (n - 1);

  // Standard deviation is the square root of the variance
  const stdev = Math.sqrt(variance);
  console.log("stdev", stdev, typeof stdev);
  return stdev.toFixed(2);
};

export const VLOOKUP = ({
  spreadsheet,
  value,
  range,
  columnIndex,
  isSorted = "FALSE",
}) => {
  const rangeSheet = range.split("!");
  const sheetIndex = rangeSheet.length > 1 ? rangeSheet[1] : "0";
  const rangeValue = rangeSheet.length > 1 ? rangeSheet[2] : rangeSheet[0];
  const [start, end] = rangeValue.split(":");

  const sheet = spreadsheet.sheets[sheetIndex];
  // chech if range is a single cell if it is then add 0 to the end
  const cellStart = start.length === 2 ? start : `${start}0`;

  // chech if range is a single cell if it is then add rows length or 100 to the end
  const cellEnd =
    end.length === 2 ? end : `${end}${sheet?.rows?.length ?? "100"}`;

  const updatedRange = `${cellStart}:${cellEnd}`;
  // replace " from start and end
  const lookupValue = value.replace(/"/g, "");

  // Get the indexes of the specified range (e.g., B2:D6 -> [2, 2, 6, 4])
  const index = getRangeIndexes(updatedRange);
  // Validate range
  if (index[0] > index[2] || index[1] > index[3]) {
    return "#REF!"; // Invalid range
  }

  // Validate column index
  if (columnIndex < 1 || columnIndex > index[3] - index[1] + 1) {
    return "#REF!"; // Invalid column index
  }

  // Extract values from the range
  const values = [];
  for (let i = index[0]; i <= index[2]; i++) {
    const row = [];
    for (let j = index[1]; j <= index[3]; j++) {
      const cell = getCell(i, j, sheet);
      row.push(cell ? cell.value : null);
    }
    values.push(row);
  }

  console.log({ updatedRange });

  // If data is sorted, use binary search
  if (isSorted === "TRUE") {
    const sortedIndex = binarySearch(values, lookupValue);
    if (sortedIndex !== -1) {
      return values[sortedIndex][columnIndex - 1];
    }
  } else if (isSorted === "FALSE") {
    // Linear search for unsorted data
    for (let i = 0; i < values.length; i++) {
      const normalizedLookupValue = lookupValue.toLowerCase();
      if (values[i][0]?.toLowerCase() === normalizedLookupValue) {
        return values[i][columnIndex - 1];
      }
    }
  } else {
    return "#REF!";
  }

  // If the value is not found, return "#N/A"
  return "#N/A";
};

// Binary search function for sorted data
const binarySearch = (values, lookupValue) => {
  let low = 0;
  let high = values.length - 1;

  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    if (values[mid][0] === lookupValue) {
      return mid;
    } else if (values[mid][0] < lookupValue) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }

  return -1;
};

export const HLOOKUP = ({
  spreadsheet,
  value,
  range,
  rowIndex,
  isSorted = "FALSE",
}) => {
  const rangeSheet = range.split("!");
  const sheetIndex = rangeSheet.length > 1 ? rangeSheet[1] : "0";
  const rangeValue = rangeSheet.length > 1 ? rangeSheet[2] : rangeSheet[0];
  const [start, end] = rangeValue.split(":");

  const sheet = spreadsheet.sheets[sheetIndex];

  // check if range is a single cell; if it is, add 0 to the end
  const cellStart = start.length === 2 ? start : `${start}0`;

  // check if range is a single cell; if it is, add rows length or 100 to the end
  const cellEnd =
    end.length === 2 ? end : `${end}${sheet?.rows?.length ?? "100"}`;

  const updatedRange = `${cellStart}:${cellEnd}`;
  // replace " from start and end
  const lookupValue = value.replace(/"/g, "").toLowerCase();

  // Get the indexes of the specified range (e.g., B2:D6 -> [2, 2, 6, 4])
  const index = getRangeIndexes(updatedRange);

  // Validate range
  if (index[0] > index[2] || index[1] > index[3]) {
    return "#REF!"; // Invalid range
  }

  // Validate row index
  if (rowIndex < 1 || rowIndex > index[2] - index[0] + 1) {
    return "#REF!"; // Invalid row index
  }

  // Extract values from the range
  const values = [];
  for (let i = index[0]; i <= index[2]; i++) {
    const row = [];
    for (let j = index[1]; j <= index[3]; j++) {
      const cell = getCell(i, j, sheet);
      row.push(cell ? cell.value : null);
    }
    values.push(row);
  }

  // If data is sorted, use binary search
  if (isSorted === "TRUE") {
    const sortedIndex = binarySearch(values[0], lookupValue);
    if (sortedIndex !== -1) {
      return values[rowIndex - 1][sortedIndex];
    }
  } else if (isSorted === "FALSE") {
    // Linear search for unsorted data
    for (let j = 0; j < values[0].length; j++) {
      const normalizedValue = values[0][j]?.toLowerCase();
      if (normalizedValue === lookupValue) {
        return values[rowIndex - 1][j];
      }
    }
  } else {
    return "#REF!";
  }

  // If the value is not found, return "#N/A"
  return "#N/A";
};

export const MATCH = (value, range, matchType, spreadsheet) => {
  const sheet = spreadsheet.getActiveSheet();
  const lookupValue = value.replace(/"/g, "");

  // Get the indexes of the specified range (e.g., B2:B6 -> [2, 2, 6, 2])
  const index = getRangeIndexes(range);
  console.log("rangeIndexes", index);

  const values = [];

  // Extract values from the range into a 1D array
  for (let i = index[0]; i <= index[2]; i++) {
    for (let j = index[1]; j <= index[3]; j++) {
      const cell = getCell(i, j, sheet);
      if (cell) {
        values.push(cell.value);
      }
    }
  }

  console.log("values", values);

  if (matchType === 0) {
    // Exact match
    for (let i = 0; i < values.length; i++) {
      if (values[i] === lookupValue) {
        return i + 1; // Return 1-based index
      }
    }
  } else if (matchType === 1) {
    // Less than or equal match (assumes the data is sorted in ascending order)
    for (let i = 0; i < values.length; i++) {
      if (values[i] === lookupValue || values[i] > lookupValue) {
        return i; // Return 1-based index of the largest value <= lookupValue
      }
    }
  } else if (matchType === -1) {
    // Greater than or equal match (assumes the data is sorted in descending order)
    for (let i = 0; i < values.length; i++) {
      if (values[i] === lookupValue || values[i] < lookupValue) {
        return i; // Return 1-based index of the smallest value >= lookupValue
      }
    }
  }

  // If no match is found, return #N/A
  return "#N/A";
};

export function UPPER(text) {
  if (typeof text !== "string") {
    return "#VALUE!";
  }
  return text.replace(/"/g, "").toUpperCase();
}

export function LOWER(text) {
  if (typeof text !== "string") {
    return "#VALUE!";
  }
  return text.replace(/"/g, "").toLowerCase();
}

export function TRIM(text) {
  if (typeof text !== "string") {
    return "#VALUE!";
  }
  return text.replace(/"/g, "").trim();
}

// excel CLEAN function
export function CLEAN(text) {
  if (typeof text !== "string") {
    return "#VALUE!";
  }
  //Removes non-printable characters from a string.
  const regex = /[^\x20-\x7E]/g;
  return text.replace(regex, "");
}

// excel SUBSTITUTE function
export function SUBSTITUTE(text, oldText, newText) {
  console.log({ text, oldText, newText });
  if (
    typeof text !== "string" ||
    typeof oldText !== "string" ||
    typeof newText !== "string"
  ) {
    return "#VALUE!";
  }
  const modText = text.replace(/"/g, "");
  return modText.replace(
    new RegExp(oldText.replace(/"/g, ""), "g"),
    newText.replace(/"/g, "")
  );
}

// excel LEFT function
export function LEFT(text, numCharsStr) {
  const numChars = parseInt(numCharsStr);
  if (
    typeof text !== "string" ||
    typeof numChars !== "number" ||
    numChars < 0
  ) {
    return "#VALUE!";
  }
  const modText = text.replace(/"/g, "");
  return modText.substring(0, numChars);
}

// excel RIGHT function
export function RIGHT(text, numCharsStr) {
  const numChars = parseInt(numCharsStr);
  if (
    typeof text !== "string" ||
    typeof numChars !== "number" ||
    numChars < 0
  ) {
    return "#VALUE!";
  }
  const modText = text.replace(/"/g, "");
  return modText.substring(modText.length - numChars, modText.length);
}

// excel MID function
export function MID(text, startNumStr, numCharsStr) {
  const startNum = parseInt(startNumStr);
  const numChars = parseInt(numCharsStr);
  if (
    typeof text !== "string" ||
    typeof startNum !== "number" ||
    typeof numChars !== "number" ||
    numChars < 0
  ) {
    return "#VALUE!";
  }
  const modText = text.replace(/"/g, "");
  return modText.substring(startNum - 1, startNum - 1 + numChars);
}
