import { create } from "zustand";
import secureLocalStorage from "react-secure-storage";
import { api } from "../utils/axios-instance";

export const useDepartment = create((set) => ({
  departments: [],
  error: false,
  loading: true,
  setDepartments: (departments) => {
    set({ departments });
  },
  setError: (error) => {
    set({ error });
  },
  setLoading: (loading) => {
    set({ loading });
  },
  listDepartments: async () => {
    set({ loading: true });
    let departmentsData = [];
    try {
      const response = await api(`/dept/listDepts`);

      departmentsData = response.data;
      secureLocalStorage.setItem(
        "departments",
        JSON.stringify(departmentsData)
      );

      console.log("DEPARTMENT DATA by Zustand: ", departmentsData);
      set({
        departments: departmentsData,
        loading: false,
        error: false,
      });
    } catch (error) {
      set({
        error: error.message,
        loading: false,
      });
    }
  },
}));
